import React from "react"
import tw, { styled } from "twin.macro"
import Button from "components/button"
import { Link } from "gatsby"
import PageHeader from "components/takeover-page-header"


const TakeoverStyle = styled.div`
  ${tw`flex flex-col mx-auto items-center relative px-2 pt-20`}

  background-image: url(${(props) => props.bg || ""});
  background-size: cover;
  background-position: bottom center;
  padding-bottom: 2rem;

  @media (min-width: 1200px) {
    min-height: calc(100vh - 8rem);
  }
  @media (min-width: 460px) {
    max-width: 90%;
  }

  .video-container {
    ${tw`relative h-[310px] m:h-full py-0 my-0 m:max-w-[800px] l:w-[800px]`}
    z-index: 1; 

    .video-preview {
      min-width: 100%;
      min-height: 100%;
      height: auto; !important;
      width: auto; !important;
      object-fit: cover;
      
    }
  }
`

const ContentWrapper = styled.div`
  ${tw`mx-auto pt-0`}
`
const ActionContainer = styled.div`
  ${tw`flex flex-col pt-10 m:pt-16 justify-center items-center`}
`

const Takeover = ({ content }) => {
  const video = content.gallery[0]
  return (
    <TakeoverStyle>
      <PageHeader
        contentClass="pt-0 pb-16 m:pt-0 l:pt-0"
        content={content.headerContent?.html}
      />
      <ContentWrapper>
        <div className="video-container">
          <Link to={content.linkUrl}>
            <video
              className="video-preview"
              playsInline={true}
              autoPlay={true}
              muted
              loop={true}
              preload="metadata"
              poster={content.featuredImage.url}
            >
              <source src={video?.url} type={video?.mimeType} />
            </video>
             
          </Link>
        </div>
        <ActionContainer>
          {content.linkUrl && (
            <Button to={content.linkUrl}>{content.callToAction}</Button>
          )}
        </ActionContainer>
      </ContentWrapper>
    </TakeoverStyle>
  )
}

export default Takeover
